// src/firebase-config.js

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database"; // Добавлен импорт для Realtime Database
import { getStorage } from "firebase/storage"; // Импорт для Storage

const firebaseConfig = {
  apiKey: "AIzaSyDaSUSldWDIc6UhMuEIPv_NacFMuJxOWLs",
  authDomain: "godelta-partner-crm.firebaseapp.com",
  databaseURL: "https://godelta-partner-crm-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "godelta-partner-crm",
  storageBucket: "godelta-partner-crm.appspot.com",
  messagingSenderId: "826343583587",
  appId: "1:826343583587:web:6ffff1be2ad190f51aad56",
  measurementId: "G-QRBNEEDS3C"
};

// Инициализация Firebase
const app = initializeApp(firebaseConfig);

// Инициализация сервисов Firebase
const auth = getAuth(app);
const firestore = getFirestore(app);
const database = getDatabase(app); // Инициализация Realtime Database
const storage = getStorage(app); // Инициализация Storage

export { auth, signInWithEmailAndPassword, firestore, database, storage }; // Экспорт Storage

