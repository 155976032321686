import React, { createContext, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const [locale, setLocale] = useState(i18n.language);

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language') || 'pl';
        console.log("Загружаем язык из localStorage:", savedLanguage);
        i18n.changeLanguage(savedLanguage);
        setLocale(savedLanguage);
    }, [i18n]);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setLocale(language);
        localStorage.setItem('language', language);
    };

    return (
        <LanguageContext.Provider value={{ locale, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => useContext(LanguageContext);
