// App.js

import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import ReactGA from 'react-ga4';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import './i18n';

import { CompanyProvider } from '@contexts/companyContext';
import { LanguageProvider, useLanguage } from './contexts/LanguageContext';

import ThemeStyles from '@styles/theme';
import './style.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-grid-layout/css/styles.css';

import { SidebarProvider } from '@contexts/sidebarContext';
import { ThemeProvider } from 'styled-components';
import { ModalProvider } from '@contexts/modalContext';
import { AuthProvider } from './contexts/authContext';
import { useThemeProvider } from '@contexts/themeContext';

import { ToastContainer } from 'react-toastify';
import Loader from '@components/Loader';
import Sidebar from '@components/Sidebar';
import BottomNav from '@components/BottomNav';
import ProtectedRoute from './components/ProtectedRoute';

const Dashboard = lazy(() => import('@pages/Dashboard'));
const Trade = lazy(() => import('@pages/Faktury'));
const Actions = lazy(() => import('@pages/Kierowcy'));
const Wallet = lazy(() => import('@pages/Rozliczenia'));
const NFT = lazy(() => import('@pages/Flota'));
const Collections = lazy(() => import('@pages/Subskrypcja'));
const SignIn = lazy(() => import('@pages/SignIn'));
const SignUp = lazy(() => import('@pages/SignUp'));
const Verification = lazy(() => import('@pages/Verification'));

const App = () => {
    const appRef = useRef(null);
    const { theme } = useThemeProvider();
    const { width } = useWindowSize();
    const { locale } = useLanguage();

    useEffect(() => {
        appRef.current && appRef.current.scrollTo(0, 0);
    }, []);

    const gaKey = process.env.REACT_APP_PUBLIC_GOOGLE_ANALYTICS;
    gaKey && ReactGA.initialize(gaKey);

    return (

        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#E0F64B',
                    borderRadius: 10,
                    colorBgContainer: '#fffbe6',
                },
            }}
            locale={locale}
        >
            
            <AuthProvider>
                <CompanyProvider>
                    <SidebarProvider>
                        <ThemeProvider theme={{ theme: theme }}>
                            <ThemeStyles />
                            <ToastContainer theme="colored" autoClose={2000} toastStyle={{ borderRadius: 4 }} />
                            <ModalProvider>
                                <div ref={appRef}>
                                    {width < 768 ? <BottomNav /> : <Sidebar />}
                                    <Suspense fallback={<Loader visible />}>
                                        <Routes>
                                            <Route path="/sign-in" element={<SignIn />} />
                                            <Route path="/sign-up" element={<SignUp />} />
                                            <Route path="/verification" element={<Verification />} />
                                            <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                                            <Route path="/faktury" element={<ProtectedRoute><Trade /></ProtectedRoute>} />
                                            <Route path="/kierowcy" element={<ProtectedRoute><Actions /></ProtectedRoute>} />
                                            <Route path="/rozliczenia" element={<ProtectedRoute><Wallet /></ProtectedRoute>} />
                                            <Route path="/flota" element={<ProtectedRoute><NFT /></ProtectedRoute>} />
                                            <Route path="/subskrypcja" element={
                                                <ProtectedRoute allowWithoutSubscription={true}><Collections /></ProtectedRoute>} />
                                        </Routes>
                                    </Suspense>
                                </div>
                            </ModalProvider>
                        </ThemeProvider>
                    </SidebarProvider>
                </CompanyProvider>
            </AuthProvider>
        </ConfigProvider>
    );
}

const AppWrapper = () => (
    <LanguageProvider>
        <App />
    </LanguageProvider>
);

export default AppWrapper;
