import React from 'react';
import ReactDOM from 'react-dom/client';
import AppWrapper from './App'; // Импортируем AppWrapper вместо App
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@contexts/themeContext';
import store from '@app/store';
import { Provider } from 'react-redux';
import './i18n';

import '@fonts/icomoon/icomoon.woff';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <ThemeProvider>
                <AppWrapper/> {/* Используем AppWrapper */}
            </ThemeProvider>
        </Provider>
    </BrowserRouter>
);
