// src/contexts/authContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../constants/firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../constants/firebase-config';

// Создаем контекст аутентификации
const AuthContext = createContext();

// Хук для использования контекста аутентификации
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Подписка на изменения состояния аутентификации
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        try {
          // Получаем данные пользователя из Firestore
          const userRef = doc(firestore, 'users', user.uid);
          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            console.log('Loaded user data:', userData);

            // Обновляем состояние currentUser с данными из Firestore
            setCurrentUser({ ...user, ...userData });
          } else {
            console.error('User data not found in Firestore');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setCurrentUser(null); // Обнуляем состояние, если пользователь не аутентифицирован
      }
      setLoading(false); // Завершаем загрузку после получения данных
    });

    return () => unsubscribe(); // Отписка при размонтировании
  }, []);

  const logout = async () => {
    await signOut(auth);
    setCurrentUser(null);
  };

  return (
    <AuthContext.Provider value={{ currentUser, loading, setCurrentUser, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
