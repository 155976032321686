// hooks/useSubscriptionStatus.js

import { useEffect, useState } from 'react';
import { useAuth } from '../contexts/authContext';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../constants/firebase-config';

export const useSubscriptionStatus = () => {
  const { currentUser, loading } = useAuth(); // Получаем текущего пользователя из контекста
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      setIsLoading(true); // Ожидаем, пока данные пользователя загрузятся
      return;
    }

    if (!currentUser || !currentUser.companyId) {
      console.warn('No currentUser or companyId available.');
      setIsSubscribed(false);
      setIsLoading(false);
      return;
    }

    const checkSubscriptionStatus = async () => {
      setIsLoading(true); // Устанавливаем состояние загрузки
      try {
        console.log('Checking subscription status for companyId:', currentUser.companyId);

        // Создаем ссылку на документ компании в Firestore
        const companyRef = doc(firestore, 'companies', currentUser.companyId);

        // Получаем данные документа
        const docSnap = await getDoc(companyRef);

        // Проверяем, существует ли документ и активен ли статус подписки
        if (docSnap.exists()) {
          const companyData = docSnap.data();
          console.log('Company data:', companyData);

          if (companyData.status === 'active') {
            setIsSubscribed(true);
          } else {
            console.warn('Subscription is not active:', companyData.status);
            setIsSubscribed(false);
          }
        } else {
          console.error('No document found for companyId:', currentUser.companyId);
          setIsSubscribed(false);
        }
      } catch (error) {
        console.error("Error checking subscription status:", error);
        setIsSubscribed(false);
      } finally {
        setIsLoading(false); // Сбрасываем состояние загрузки
      }
    };

    checkSubscriptionStatus(); // Вызываем функцию проверки статуса подписки
  }, [currentUser, loading]); // Добавляем currentUser и loading в зависимости useEffect

  return { isSubscribed, isLoading }; // Возвращаем статус подписки и состояние загрузки
};
