// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';
import translationPL from './locales/pl/translation.json';
import translationUK from './locales/uk/translation.json';
import translationKA from './locales/ka/translation.json';

// Get language from localStorage or use default
const savedLanguage = localStorage.getItem('language') || 'pl';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEN },
      ru: { translation: translationRU },
      pl: { translation: translationPL },
      uk: { translation: translationUK },
      ka: { translation: translationKA },
    },
    lng: savedLanguage, // Load the language from localStorage
    fallbackLng: 'pl',
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
