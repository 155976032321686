// styling
import styles from './style.module.scss';

// components
import { NavLink } from 'react-router-dom';

// constants
import getLinks from '@constants/links'; // Import the function, not the default constant

// utils
import { memo, useMemo } from 'react';

// hooks
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useThemeProvider } from '@contexts/themeContext';
import useAuthRoute from '@hooks/useAuthRoute';
import { useTranslation } from 'react-i18next';

const BottomNav = () => {
    const ref = useRef(null);
    const location = useLocation();
    const { theme } = useThemeProvider();
    const isActive = (path) => location.pathname === path;
    const isAuthRoute = useAuthRoute();
    const { t } = useTranslation(); // Initialize translation hook

    // Memoize LINKS to avoid unnecessary re-computation on each render
    const LINKS = useMemo(() => {
        const links = getLinks(t);
        console.log('Computed LINKS:', links); // Debugging: Check if LINKS is correctly populated
        return links;
    }, [t]); // Recompute LINKS only when `t` changes

    useEffect(() => {
        const height = ref.current.offsetHeight;
        window.document.documentElement.style.setProperty('--bottom-nav-height', `${height}px`);

        return () => {
            window.document.documentElement.style.removeProperty('--bottom-nav-height');
        }
    }, []);

    return (
        <>
            {
                !isAuthRoute ? (
                    <div className={`${styles.container} ${theme === 'dark' ? styles.dark : ''}`} ref={ref}>
                        {
                            LINKS.map(link => {
                                const { icon, path, desktop, mobile } = link;
                                const isSm = icon === 'collections';

                                return (
                                    <NavLink className={`nav-link ${isActive(path) ? 'active' : ''}`} to={path} key={path}>
                                        <span className="wrapper">
                                            <i className={`icon icon-${icon} ${isSm ? 'sm' : ''}`} />
                                            <i className={`icon icon-fill ${isActive(path) ? 'active' : ''} icon-${icon}${icon !== 'trade' ? '-active' : ''} ${isSm ? 'sm' : ''}`} />
                                        </span>
                                        {mobile || desktop} {/* Use mobile if available, otherwise fallback to desktop */}
                                    </NavLink>
                                )
                            })
                        }
                    </div>
                ) : null
            }
        </>
    )
}

export default memo(BottomNav);
