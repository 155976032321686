import React, { useState, useEffect, useMemo } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../constants/firebase-config';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { NavLink } from 'react-router-dom';
import Switch from '@ui/Switch';
import { Modal, Select } from 'antd';
import 'antd/dist/reset.css';
import { useSidebar } from '@contexts/sidebarContext';
import { useWindowSize } from 'react-use';
import { useThemeProvider } from '@contexts/themeContext';
import { useModal } from '@contexts/modalContext';
import useAuthRoute from '@hooks/useAuthRoute';
import { useAuth } from '@contexts/authContext';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';
import getLinks from '@constants/links'; // Correctly import getLinks
import notifications from '@db/notifications.js';
import logo from '@assets/logo.svg';
import avatar from '@assets/avatar.webp';
import styles from './style.module.scss'; // Import styles

const Sidebar = () => {
    const { currentUser } = useAuth();
    const [userDetails, setUserDetails] = useState({ firstName: '', lastName: '' });
    const [isLanguageModalVisible, setLanguageModalVisible] = useState(false);

    // Fetch user details from Firestore
    useEffect(() => {
        const fetchUserDetails = async () => {
            if (currentUser) {
                const docRef = doc(firestore, "users", currentUser.uid);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setUserDetails({
                        firstName: docSnap.data().firstName,
                        lastName: docSnap.data().lastName,
                    });
                } else {
                    console.log("No such document!");
                }
            }
        };

        fetchUserDetails();
    }, [currentUser]);

    const { open, setOpen } = useSidebar();
    const { modal, handleOpen } = useModal();
    const { theme, setTheme } = useThemeProvider();
    const windowSize = useWindowSize();
    const isTemporary = windowSize.width < 1920;
    const unreadNotifications = notifications.filter(notification => !notification.read).length;
    const isActive = (path) => window.location.pathname === path;
    const isAuthRoute = useAuthRoute();

    const { t, i18n } = useTranslation();

    // Memoize LINKS to avoid unnecessary re-computation on each render
    const LINKS = useMemo(() => {
        // Debugging output to check the translation function and returned links
        const links = getLinks(t);

        return links;
    }, [t]);

    // Debugging output for LINKS and windowSize
    useEffect(() => {

        if (!Array.isArray(LINKS)) {
            console.error('LINKS is not an array!', LINKS);
        }
    }, [LINKS, windowSize]);

    const changeLanguage = async (language) => {
        try {
            await i18n.changeLanguage(language);
            localStorage.setItem('language', language);
            setLanguageModalVisible(false);
        } catch (error) {
            console.error("Ошибка при смене языка:", error);
        }
    };

    const languageOptions = [
        { value: 'pl', label: 'Polski' },
        { value: 'ru', label: 'Русский' },
        { value: 'uk', label: 'Українська' },
        { value: 'en', label: 'English' },
        { value: 'ka', label: 'ქართული' }
    ];

    return (
        !isAuthRoute ? (
            <>
                <SwipeableDrawer
                    anchor="left"
                    open={isTemporary ? open : true}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    variant={isTemporary ? 'temporary' : 'persistent'}
                    PaperProps={{
                        sx: {
                            width: 310,
                            padding: '32px 32px 40px',
                            backgroundColor: 'var(--widget)',
                            color: theme === 'light' ? 'var(--header)' : 'var(--text)',
                            fontSize: '1rem',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            height: '100%',
                            fontWeight: '600 !important'
                        }
                    }}>
                    <NavLink className={styles.logo} to="/">
                        <img src={logo} alt="logo" style={{ width: 33 }} />
                        <span className="h1">fleetify</span>
                    </NavLink>
                    <div className={`${styles.list} flex-1`}>
                        {LINKS.map(link => (
                            <NavLink className={`nav-link nav-link--sidebar ${styles.list_item}`}
                                key={link.desktop}
                                to={link.path}>
                                <span className="wrapper">
                                    <i className={`icon icon-${link.icon === 'home' ? 'dashboard' : link.icon} ${link.icon === 'collections' ? styles.sm : ''} ${isActive(link.path) ? '' : 'active'}`} />
                                    <i className={`icon icon-fill ${isActive(link.path) ? 'active' : ''} icon-${link.icon === 'home' ? 'dashboard' : link.icon}${link.icon !== 'trade' ? '-active' : ''} ${link.icon === 'collections' ? styles.sm : ''}`} />
                                </span>
                                {windowSize.width < 768 ? link.mobile || link.desktop : link.desktop} {/* Use mobile version if width is less than 768px */}
                            </NavLink>
                        ))}
                    </div>
                    <div className="d-flex flex-column g-40 w-100">
                        <div className={styles.list}>
                            <button className={`nav-link nav-link--sidebar ${styles.list_item} ${modal === 'support' ? 'active' : ''}`}
                                onClick={() => handleOpen('support')}>
                                <span className="wrapper">
                                    <i className={`icon icon-headset ${styles.sm} ${modal !== 'support' ? 'active' : ''}`} />
                                    <i className={`icon icon-headset icon-fill ${styles.sm} ${modal === 'support' ? 'active' : ''}`} />
                                </span>
                                {t('support')}
                            </button>
                            <button className={`nav-link nav-link--sidebar ${styles.list_item}`}
                                onClick={() => setLanguageModalVisible(true)}>
                                <span className="wrapper">
                                    <i className="icon icon-lang" />
                                </span>
                                {t('language')}
                            </button>
                            <div className="d-flex justify-content-between align-items-center">
                                <button className={`nav-link nav-link--sidebar ${styles.list_item} ${modal === 'notifications' ? 'active' : ''}`}
                                    onClick={() => handleOpen('notifications')}>
                                    <span className="wrapper">
                                        <i className={`icon icon-notification ${styles.sm} ${modal !== 'notifications' ? 'active' : ''}`} />
                                        <i className={`icon icon-notification-active icon-fill ${styles.sm} ${modal === 'notifications' ? 'active' : ''}`} />
                                    </span>
                                    {t('notifications')}
                                </button>
                                <span className="indicator indicator--text">{unreadNotifications}</span>
                            </div>
                            <button className={`nav-link nav-link--sidebar ${styles.list_item} ${modal === 'info' ? 'active' : ''}`}
                                onClick={() => handleOpen('info')}>
                                <span className="wrapper">
                                    <i className={`icon icon-info ${modal !== 'info' ? 'active' : ''}`} />
                                    <i className={`icon icon-info-active icon-fill ${modal === 'info' ? 'active' : ''}`} />
                                </span>
                                {t('fleetify_info')}
                            </button>

                        </div>
                        <div className="d-flex align-items-center g-16 text-header">
                            <img className="avatar" src={avatar} alt={`${userDetails.firstName} ${userDetails.lastName}`} />
                            <button className="text-600" onClick={() => handleOpen('account')}>
                                {userDetails.firstName && userDetails.lastName ? `${userDetails.firstName} ${userDetails.lastName}` : 'Loading...'}
                            </button>
                        </div>
                    </div>
                </SwipeableDrawer>

                <Modal
                    title={t('select_language')}
                    open={isLanguageModalVisible}
                    onCancel={() => setLanguageModalVisible(false)}
                    footer={null}
                >
                    <Select
                        value={i18n.language}
                        style={{ width: '100%' }}
                        onChange={changeLanguage}
                        options={languageOptions}
                        popupMatchSelectWidth={false}
                    />
                </Modal>
            </>
        ) : null
    );
}

export default memo(Sidebar);
