import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import { useSubscriptionStatus } from '@hooks/useSubscriptionStatus';

const ProtectedRoute = ({ children, allowWithoutSubscription = false }) => {
    const { currentUser, loading: authLoading } = useAuth();
    const { isSubscribed, isLoading: subscriptionLoading } = useSubscriptionStatus();

    // Отображаем индикатор загрузки, если идет проверка аутентификации или подписки
    if (authLoading || subscriptionLoading) {
        return <div>Loading...</div>;
    }

    // Если пользователь не аутентифицирован, перенаправляем на страницу входа
    if (!currentUser) {
        return <Navigate to="/sign-in" replace />;
    }

    // Если подписка не активна и доступ без подписки не разрешен, перенаправляем на страницу подписки
    if (!isSubscribed && !allowWithoutSubscription) {
        return <Navigate to="/subskrypcja" replace />;
    }

    // Во всех остальных случаях отображаем защищенный контент
    return children;
};


export default ProtectedRoute;
